// EditCodeLang.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import config from "../../../config";
import CodeLangSingleForm from "../../../components/organisms/CodeLangSingleForm/CodeLangSingleForm";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import EmailPreview from "../../../components/organisms/EmailPreview/EmailPreview";
import TextInput from "../../../components/atoms/TextInput/TextInput";
import HelpPopUp from "../../../components/organisms/HelpPopUp/HelpPopUp";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch";
import Loader from "../../../components/atoms/Loader/Loader";
import Button from "../../../components/atoms/Button/Button";
import './EditCodeLang.css';

const EditCodeLang = () => {
    const { codeLangId, campaignId } = useParams(); // Ajout de campaignId
    const [placeholdersData, setPlaceholdersData] = useState({});
    const [emailObject, setEmailObject] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const { triggerNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(false);

    // États pour la duplication des traductions
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [duplicateCodeLangSingleKey, setDuplicateCodeLangSingleKey] = useState('');
    const [similarCodeLangSingles, setSimilarCodeLangSingles] = useState([]);
    const [selectedCodeLangSingle, setSelectedCodeLangSingle] = useState([]);
    const [isPopupLoading, setIsPopupLoading] = useState(false);
    const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
    const [popupError, setPopupError] = useState('');

    // États pour la duplication des objets d'email
    const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
    const [similarEmailObjects, setSimilarEmailObjects] = useState([]);
    const [selectedEmailObjects, setSelectedEmailObjects] = useState([]);
    const [isEmailPopupLoading, setIsEmailPopupLoading] = useState(false);
    const [isEmailDuplicateLoading, setIsEmailDuplicateLoading] = useState(false);
    const [emailPopupError, setEmailPopupError] = useState('');

    const helpInstructions = [
        "[[ login ]] : Affiche le login de l'éligible si renseigné",
        "[[ var_1 ]]: Affiche la variable 1  de l'éligible si renseignée",
        "[[ var_2 ]]: Affiche la variable 2  de l'éligible si renseignée",
        "[[ var_3 ]]: Affiche la variable 3  de l'éligible si renseignée",
        "[[ reference_price_global_iso ]]: Prix de référence en euro ISO",
        "[[ reference_price_global_sigle ]]: Prix de référence en euro Sigle (€)",
        "[[ subscription_price_global_iso ]]: Prix de souscription en euro ISO",
        "[[ subscription_price_global_sigle ]]: Prix de souscription en euro Sigle (€)",
        "[[ reference_price_local_iso ]]: Prix de référence en monnaie locale (si renseigné) en ISO",
        "[[ reference_price_local_sigle ]]: Prix de référence en monnaie locale (si renseigné) avec sigle (Si existant)",
        "[[ subscription_price_local_iso ]]: Prix de souscription en monnaie locale (si renseigné), en ISO",
        "[[ subscription_price_local_sigle ]]: Prix de souscription en monnaie locale (si renseigné) avec sigle (Si existant)",
    ];
    const authFetch = useAuthFetch(); // Nouvelle constante

    const fetchData = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/codelang/fetch/${codeLangId}`);
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error);
            }
            const data = await response.json();
            setPlaceholdersData(data.placeholders || {});
            setEmailObject(data.email_object);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        }
    }, [codeLangId, triggerNotification, authFetch]);

    const fetchEmailPreview = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/email/simulation/${codeLangId}`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Erreur lors de la simulation');
            }
            setEmailContent(data.content);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        }
    }, [codeLangId, triggerNotification, authFetch]);

    useEffect(() => {
        fetchData();
        fetchEmailPreview();
    }, [fetchData, fetchEmailPreview]);

    const handleEmailObjectChange = (newEmailObject) => {
        setEmailObject(newEmailObject);
    };

    const saveAllChanges = async () => {
        setIsLoading(true);
        try {
            // Créer une promesse pour mettre à jour l'objet de l'email
            const updateEmailObject = authFetch(`${config.API_URL}/emailing/codelang/update-email-object/${codeLangId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email_object: emailObject })
            }).then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.error || 'Une erreur est survenue lors de la mise à jour de l\'objet de l\'email');
                    });
                }
            });

            // Créer une promesse pour mettre à jour les placeholders
            const updatePlaceholders = Promise.all(Object.entries(placeholdersData).map(async ([codeLangSingleKey, placeholders]) => {
                return Promise.all(placeholders.map(async (placeholder) => {
                    await authFetch(`${config.API_URL}/emailing/placeholder/update/${placeholder.uuid}`, {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ value: placeholder.value })
                    });
                }));
            }));

            // Exécuter les deux mises à jour en parallèle
            await Promise.all([updateEmailObject, updatePlaceholders]);

            // Récupérer l'aperçu de l'email mis à jour
            await fetchEmailPreview();

            // Notifier l'utilisateur du succès
            triggerNotification({
                type: 'success',
                content: 'Les changements ont été sauvegardés avec succès.',
                duration: 3000,
                persistent: false
            });
        } catch (error) {
            // Notifier l'utilisateur de l'erreur
            triggerNotification({
                type: 'error',
                content: "Erreur lors de la sauvegarde des changements: " + error.message,
                duration: 3000,
                persistent: false
            });
            console.error("Erreur lors de la mise à jour des données:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleContentChange = (codeLangSingleKey, updatedPlaceholders) => {
        setPlaceholdersData(prevState => ({
            ...prevState,
            [codeLangSingleKey]: updatedPlaceholders
        }));
    };

    // Fonctions de duplication des traductions
    const handleDuplicateClick = async (codeLangSingleKey) => {
        setDuplicateCodeLangSingleKey(codeLangSingleKey);
        setIsPopupOpen(true);
        setIsPopupLoading(true);
        setPopupError('');
        try {
            const params = new URLSearchParams({
                code_lang_uuid: codeLangId,
                code_lang_single: codeLangSingleKey
            });

            const response = await authFetch(`${config.API_URL}/emailing/codelang/list-similar?${params.toString()}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Erreur lors de la récupération des éléments similaires');
            }
            setSimilarCodeLangSingles(data || []);
        } catch (error) {
            setPopupError(error.message);
        } finally {
            setIsPopupLoading(false);
        }
    };

    const handleCheckboxChange = (code_lang_uuid) => {
        setSelectedCodeLangSingle(prevSelected => {
            if (prevSelected.includes(code_lang_uuid)) {
                return prevSelected.filter(id => id !== code_lang_uuid);
            } else {
                return [...prevSelected, code_lang_uuid];
            }
        });
    };

    const handleValidateDuplicate = async () => {
        setIsDuplicateLoading(true);
        setPopupError('');
        try {
            const response = await authFetch(`${config.API_URL}/emailing/codelang/duplicate-email-placeholders`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    original_code_lang_single: duplicateCodeLangSingleKey,
                    original_code_lang_uuid: codeLangId,
                    selected_code_lang_single: selectedCodeLangSingle
                })
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Erreur lors de la duplication');
            }
            triggerNotification({
                type: 'success',
                content: 'Duplication réussie.',
                duration: 3000,
                persistent: false
            });
            // Rafraîchir les données après duplication
            fetchData();
            fetchEmailPreview();
            handleClosePopup();
        } catch (error) {
            setPopupError(error.message);
        } finally {
            setIsDuplicateLoading(false);
        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setDuplicateCodeLangSingleKey('');
        setSimilarCodeLangSingles([]);
        setSelectedCodeLangSingle([]);
        setPopupError('');
    };

    // Fonctions de duplication des objets d'email
    const handleEmailDuplicateClick = async () => {
        setIsEmailPopupOpen(true);
        setIsEmailPopupLoading(true);
        setEmailPopupError('');
        setSelectedEmailObjects([]); // Réinitialiser les sélections
        try {
            const response = await authFetch(`${config.API_URL}/emailing/codelang/list?campaign_uuid=${campaignId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Erreur lors de la récupération des objets email similaires');
            }
            setSimilarEmailObjects(data || []);
        } catch (error) {
            setEmailPopupError(error.message);
        } finally {
            setIsEmailPopupLoading(false);
        }
    };

    const handleEmailCheckboxChange = (uuid) => {
        setSelectedEmailObjects(prevSelected => {
            if (prevSelected.includes(uuid)) {
                return prevSelected.filter(id => id !== uuid);
            } else {
                return [...prevSelected, uuid];
            }
        });
    };

    const handleValidateEmailDuplicate = async () => {
        setIsEmailDuplicateLoading(true);
        setEmailPopupError('');
        try {
            const response = await authFetch(`${config.API_URL}/emailing/codelang/duplicate-email-object`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    original_code_lang_uuid: codeLangId,
                    selected_code_lang_uuids: selectedEmailObjects
                })
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Erreur lors de la duplication de l\'objet email');
            }

            triggerNotification({
                type: 'success',
                content: 'Duplication de l\'objet email réussie.',
                duration: 3000,
                persistent: false
            });

            // Rafraîchir les données après duplication
            fetchData();
            fetchEmailPreview();
            handleCloseEmailPopup();
        } catch (error) {
            setEmailPopupError(error.message);
        } finally {
            setIsEmailDuplicateLoading(false);
        }
    };

    const handleCloseEmailPopup = () => {
        setIsEmailPopupOpen(false);
        setSimilarEmailObjects([]);
        setSelectedEmailObjects([]);
        setEmailPopupError('');
    };

    return (
        <div>
            {isLoading && <Loader />}
            <Button onClick={saveAllChanges} className="button-fixed-bottom right">Sauvegarder les changements</Button>
            <Breadcrumb />
            <HelpPopUp instructions={helpInstructions} />

            <h1>Modifier le Code Lang</h1>
            <div className="label-object-container">
                <label className="label-object-email" htmlFor="emailObject"><strong>Objet de l'email</strong>:</label>
                <TextInput
                    id="emailObject"
                    value={emailObject}
                    onChange={(e) => handleEmailObjectChange(e.target.value)}
                    placeholder="Objet de l'email"
                />
                <button className="duplicate-button button" onClick={handleEmailDuplicateClick}>
                    Dupliquer l'objet
                    <span className="tooltip-text">
                        Sauvegarde nécessaire avant: dupliquera l'objet de l'email vers les campagnes choisies
                    </span>
                </button>
            </div>

            {placeholdersData && Object.entries(placeholdersData).map(([codeLangSingleKey, placeholders]) => (
                <CodeLangSingleForm
                    key={codeLangSingleKey}
                    codeLangSingle={codeLangSingleKey}
                    placeholders={placeholders}
                    onContentChange={handleContentChange}
                    onDuplicate={handleDuplicateClick} // Passage de la fonction de duplication
                />
            ))}

            <EmailPreview content={emailContent} />

            {/* Popup de duplication des traductions */}
            {isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <button className="close-button" onClick={handleClosePopup}>&times;</button>
                        <h2>Dupliquer "{duplicateCodeLangSingleKey}"</h2>
                        {isPopupLoading ? (
                            <Loader />
                        ) : (
                            <>
                                {popupError && <div className="error-message">{popupError}</div>}
                                <form>
                                    {similarCodeLangSingles.length > 0 ? (
                                        similarCodeLangSingles.map(item => (
                                            <div key={item.code_lang_uuid} className="checkbox-item">
                                                <input
                                                    type="checkbox"
                                                    id={`translation-${item.code_lang_uuid}`}
                                                    value={item.code_lang_single}
                                                    checked={selectedCodeLangSingle.includes(item.code_lang_single)}
                                                    onChange={() => handleCheckboxChange(item.code_lang_single)}
                                                />
                                                <label htmlFor={`translation-${item.code_lang_uuid}`}>
                                                    {item.code_lang_single} ({item.country} - {item.language})
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <p>Aucun élément similaire trouvé.</p>
                                    )}
                                </form>
                                <Button
                                    onClick={handleValidateDuplicate}
                                    disabled={selectedCodeLangSingle.length === 0 || isDuplicateLoading}
                                    className="validate-button"
                                >
                                    {isDuplicateLoading ? 'Validation...' : 'Valider'}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            )}

            {/* Popup de duplication des objets d'email */}
            {isEmailPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <button className="close-button" onClick={handleCloseEmailPopup}>&times;</button>
                        <h2>Dupliquer l'objet email</h2>
                        {isEmailPopupLoading ? (
                            <Loader />
                        ) : (
                            <>
                                {emailPopupError && <div className="error-message">{emailPopupError}</div>}
                                <form>
                                    {similarEmailObjects.length > 0 ? (
                                        similarEmailObjects.map(item => (
                                            <div key={item.uuid} className="checkbox-item">
                                                <input
                                                    type="checkbox"
                                                    id={`email-${item.uuid}`}
                                                    value={item.uuid}
                                                    checked={selectedEmailObjects.includes(item.uuid)}
                                                    onChange={() => handleEmailCheckboxChange(item.uuid)}
                                                />
                                                <label htmlFor={`email-${item.uuid}`}>{item.code_lang} ({item.country})</label>
                                            </div>
                                        ))
                                    ) : (
                                        <p>Aucun objet email similaire trouvé.</p>
                                    )}
                                </form>
                                <Button
                                    onClick={handleValidateEmailDuplicate}
                                    disabled={selectedEmailObjects.length === 0 || isEmailDuplicateLoading}
                                    className="validate-button"
                                >
                                    {isEmailDuplicateLoading ? 'Validation...' : 'Valider'}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );

};

export default EditCodeLang;
