// src/pages/EditTemplate/EditTemplate.js
import React, { useState, useEffect } from 'react';
import TemplateForm from '../../../components/organisms/TemplateForm/TemplateForm';
import { useParams, useNavigate } from 'react-router-dom';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch"; // Nouvelle importation

const EditTemplate = () => {
    const { campaignId, templateId } = useParams();
    const navigate = useNavigate();
    const [initialData, setInitialData] = useState({});
    const { triggerNotification } = useNotification();
    const authFetch = useAuthFetch(); // Nouvelle constante

    useEffect(() => {
        const fetchTemplateData = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/template/fetch/${templateId}`); // Remplacement de fetch par authFetch
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données du template');
                }
                const data = await response.json();
                setInitialData(data);
            } catch (error) {
                console.error("Erreur:", error);
            }
        };

        fetchTemplateData();
    }, [templateId, authFetch]);

    const handleSubmit = async (templateData) => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/template/update/${templateId}`, { // Remplacement de fetch par authFetch
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(templateData),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la liaison')
            }

            triggerNotification({
                type: 'success',
                content: 'Template mis à jour avec succès.',
                duration: 3000,
                persistent: false
            });
            setTimeout(() => navigate(`/campagnes/${campaignId}`), 800);
            navigate(`/campagnes/${campaignId}`);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: true
            });
        }
    };

    return (
        <div>
            <Breadcrumb />
            <h1>Éditer le Template</h1>
            <TemplateForm
                onSubmit={handleSubmit}
                initialData={initialData} />
        </div>
    );
};

export default EditTemplate;
