import React from 'react';
import TextInput from "../../atoms/TextInput/TextInput";
import Button from "../../atoms/Button/Button";
import './EligibleField.css';

const EligibleFields = ({ index, eligible, onEligibleChange, onDelete, codeLangOptions }) => (
    <div className="eligible-field">
        <div className="form-group">
            <TextInput
                value={eligible.email}
                onChange={e => onEligibleChange(index, 'email', e.target.value)}
                placeholder="Email"
            />
            <TextInput
                value={eligible.var1}
                onChange={e => onEligibleChange(index, 'var1', e.target.value)}
                placeholder="Var1"
            />
            <TextInput
                value={eligible.var2}
                onChange={e => onEligibleChange(index, 'var2', e.target.value)}
                placeholder="Var2"
            />
            <TextInput
                value={eligible.var3}
                onChange={e => onEligibleChange(index, 'var3', e.target.value)}
                placeholder="Var3"
            />
            <TextInput
                value={eligible.login}
                onChange={e => onEligibleChange(index, 'login', e.target.value)}
                placeholder="Login"
            />

            <Button onClick={() => onDelete(index)}>Supprimer</Button>
        </div>
    </div>
);

export default EligibleFields;
