import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import config from "../../../config";
import Button from "../../../components/atoms/Button/Button";
import CodeLangFields from "../../../components/molecules/CodeLangField/CodeLangField";
import './AddCodeLang.css';
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch";
import Loader from "../../../components/atoms/Loader/Loader"; // Ajout de l'import

const AddCodeLang = () => {
    const { triggerNotification } = useNotification();
    const navigate = useNavigate();
    const { campaignId } = useParams();
    const authFetch = useAuthFetch(); // Initialisation de authFetch
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [codeLangs, setCodeLangs] = useState([{ code_lang: '' }]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            triggerNotification({
                type: 'error',
                content: "Veuillez sélectionner un fichier Excel.",
                duration: 3000,
                persistent: false
            });
            return;
        }
        setIsLoading(true);
        const reader = new FileReader();

        reader.onload = async (e) => {
            try {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                // Vérifier les entêtes
                const requiredHeaders = ["Code"];
                const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
                const missingHeaders = requiredHeaders.filter(h => !headers.includes(h));
                if (missingHeaders.length > 0) {
                    triggerNotification({
                        type: 'error',
                        content: `Les entêtes suivantes sont manquantes dans le fichier Excel : ${missingHeaders.join(", ")}`,
                        duration: 3000,
                        persistent: true
                    });
                    setIsLoading(false);
                    return;
                }

                // Filtrer les lignes non vides et formater les données
                const formattedData = jsonData
                    .filter(row => row['Code'] && row['Code'].toString().trim() !== '')
                    .map(row => ({
                        code_lang: row['Code'].toString().trim()
                    }));

                if (formattedData.length === 0) {
                    triggerNotification({
                        type: 'error',
                        content: 'Aucun Code Langue valide trouvé dans le fichier Excel.',
                        duration: 3000,
                        persistent: true
                    });
                    setIsLoading(false);
                    return;
                }

                // Envoyer les données à l'API
                await sendDataToApi(formattedData);

                triggerNotification({
                    type: 'success',
                    content: 'Codes ajoutés avec succès.',
                    duration: 3000,
                    persistent: false
                });
                setTimeout(() => {
                    navigate(`/campagnes/${campaignId}`);
                }, 800);
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message || 'Erreur lors de la communication avec l’API.',
                    duration: 3000,
                    persistent: true
                });
            } finally {
                setIsLoading(false);
            }
        };

        reader.readAsBinaryString(file);
    };

    const sendDataToApi = async (data) => {
        const payload = data.map(item => ({ ...item, campaign_uuid: campaignId }));
        try {
            const response = await authFetch(`${config.API_URL}/emailing/codelang/add-multiple`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const responseData = await response.json();
                throw new Error(responseData.error || 'Erreur lors de l’envoi des données.');
            }

            // Optionnel : Traiter la réponse si nécessaire
            // const responseData = await response.json();
        } catch (error) {
            throw new Error(error.message || 'Erreur lors de la communication avec l’API.');
        }
    };

    const addCodeLangField = () => {
        setCodeLangs([...codeLangs, { code_lang: '' }]);
    };

    const updateCodeLangField = (index, value) => {
        const updatedCodeLangs = codeLangs.map((codeLang, i) => {
            if (i === index) {
                return { ...codeLang, code_lang: value };
            }
            return codeLang;
        });
        setCodeLangs(updatedCodeLangs);
    };

    const deleteCodeLangField = (index) => {
        setCodeLangs(codeLangs.filter((_, i) => i !== index));
    };

    const handleSubmitForm = async () => {
        if (codeLangs.length === 0) {
            triggerNotification({
                type: 'error',
                content: 'Aucun Code Langue à envoyer.',
                duration: 3000,
                persistent: false
            });
            return;
        }

        // Filtrer les lignes non vides
        const validData = codeLangs
            .filter(codeLang => codeLang.code_lang && codeLang.code_lang.trim() !== '')
            .map(codeLang => ({
                code_lang: codeLang.code_lang.trim()
            }));

        if (validData.length === 0) {
            triggerNotification({
                type: 'error',
                content: 'Aucun Code Langue valide à envoyer.',
                duration: 3000,
                persistent: false
            });
            return;
        }

        setIsLoading(true);

        try {
            await sendDataToApi(validData);

            triggerNotification({
                type: 'success',
                content: 'Codes ajoutés avec succès.',
                duration: 3000,
                persistent: false
            });
            setTimeout(() => {
                navigate(`/campagnes/${campaignId}`);
            }, 800);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: true
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {isLoading && <Loader />}
            <Breadcrumb />
            <h1>Ajouter des Codes Langues</h1>

            <h2>Importez un fichier Excel</h2>
            <p>Veuillez préparer un fichier Excel avec une colonne nommée "<b>Code</b>".</p>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            <Button onClick={handleUpload} disabled={!file || isLoading}>
                {isLoading ? 'Chargement...' : 'Envoyer le fichier'}
            </Button>

            <h2>Ou, remplissez directement les champs</h2>
            <div id="codeLang-fields">
                {codeLangs.map((codeLang, index) => (
                    <CodeLangFields
                        key={index}
                        index={index}
                        codeLang={codeLang.code_lang}
                        onCodeLangChange={(value) => updateCodeLangField(index, value)}
                        onDelete={() => deleteCodeLangField(index)}
                    />
                ))}
            </div>

            <div className="validation">
                <Button onClick={addCodeLangField}>Ajouter un Code Langue</Button>
                <Button onClick={handleSubmitForm} disabled={isLoading}>
                    {isLoading ? 'Chargement...' : 'Envoyer les Codes Langues'}
                </Button>
            </div>
        </div>
    );
};

export default AddCodeLang;