import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import config from "../../../config";
import Button from "../../../components/atoms/Button/Button";
import EligibleFields from "../../../components/molecules/EligibleField/EligibleField";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import Loader from "../../../components/atoms/Loader/Loader";
import HelpPopUp from "../../../components/organisms/HelpPopUp/HelpPopUp";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch";

const AddEligible = () => {
    const { triggerNotification } = useNotification();
    const navigate = useNavigate();
    const { campaignId, batchId } = useParams();
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [eligibles, setEligibles] = useState([]);
    const authFetch = useAuthFetch();

    const helpInstructions = [
        "Excel: Les entêtes de colonne Var1 Var2 Var3 Login  sont obligatoires, même si les valeurs sont vides",
        "L'ordre est important. Email en A1 Var1 en B1, Var2 en C1, Var3 en D1, Login en E1",
        "La casse est importante. Respectez la majuscule en début de mot",
    ];

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const cleanEmail = (email) => {
        // eslint-disable-next-line no-control-regex
        return email.replace(/[\s\x00-\x1F\x7F-\x9F]+/g, "").trim();
    };

    const handleUpload = async () => {
        setEligibles([]);

        if (!file) {
            triggerNotification({
                type: 'error',
                content: 'Veuillez sélectionner un fichier Excel.',
                duration: 3000,
                persistent: false
            });
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const requiredHeaders = ["Email", "Var1", "Var2", "Var3","Login"];
            const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
            const missingHeaders = requiredHeaders.filter(h => !headers.includes(h));
            if (missingHeaders.length > 0) {
                triggerNotification({
                    type: 'error',
                    content: `Les entêtes suivantes sont manquantes dans le fichier Excel : ${missingHeaders.join(", ")}`,
                    duration: 3000,
                    persistent: true
                });
                setIsLoading(false);
                return;
            }

            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            const formattedData = jsonData.map(row => ({
                email: cleanEmail(row['Email'].trim()) || '',
                var1: row['Var1'] || '',
                var2: row['Var2'] || '',
                var3: row['Var3'] || '',
                login: row['Login'] || '',
            }));

            // Validation des emails et suppression des doublons
            const validData = [];
            const invalidEmails = [];
            const seenEmails = new Set();

            formattedData.forEach((row) => {
                const email = row.email;

                if (!isValidEmail(email)) {
                    invalidEmails.push({ email, reason: 'Email invalide' });
                } else if (seenEmails.has(email)) {
                    invalidEmails.push({ email, reason: 'Email en doublon' });
                } else {
                    seenEmails.add(email);
                    validData.push(row);
                }
            });

            if (invalidEmails.length > 0) {
                // Télécharger le fichier Excel des emails invalides
                downloadInvalidEmailsExcel(invalidEmails);
                triggerNotification({
                    type: 'warning',
                    content: `Des emails invalides ou en doublon ont été trouvés et enregistrés dans un fichier Excel.`,
                    duration: 5000,
                    persistent: true
                });
            }

            if (validData.length === 0) {
                triggerNotification({
                    type: 'error',
                    content: 'Aucun email valide trouvé pour l\'envoi.',
                    duration: 3000,
                    persistent: true
                });
                setIsLoading(false);
                return;
            }

            const chunkSize = 20000;
            let errorOccurred = false;

            for (let i = 0; i < validData.length; i += chunkSize) {
                if (errorOccurred) break;

                const chunk = validData.slice(i, i + chunkSize);
                try {
                    await sendDataToApi(chunk);
                } catch (error) {
                    console.error("Erreur lors de l'envoi d'un batch:", error);
                    errorOccurred = true;
                    triggerNotification({
                        type: 'error',
                        content: error.message || 'Erreur lors de l’envoi des données.',
                        duration: 3000,
                        persistent: false
                    });
                    setIsLoading(false);
                }
            }

            if (!errorOccurred) {
                triggerNotification({
                    type: 'success',
                    content: 'Éligibles ajoutés avec succès',
                    duration: 800,
                    persistent: false
                });
                setIsLoading(false);
                setTimeout(() => {
                    navigate(`/campagnes/${campaignId}/batch/${batchId}`);
                }, 800);
            }
            setIsLoading(false);
        };

        reader.readAsBinaryString(file);
    };

    const downloadInvalidEmailsExcel = (invalidEmails) => {
        const worksheet = XLSX.utils.json_to_sheet(invalidEmails);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Emails Invalides');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'emails_invalides.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const sendDataToApi = async (data) => {
        data = data.map(eligible => ({ ...eligible, batch_uuid: batchId }));
        data.batch_uuid = batchId;
        try {
            const response = await authFetch(`${config.API_URL}/emailing/eligible/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                const responseData = await response.json();
                const errorMessage = responseData.error || 'Erreur lors de l’envoi des données.';
                triggerNotification({
                    type: 'error',
                    content: errorMessage,
                    duration: 3000,
                    persistent: false
                });
                setIsLoading(false);
                throw new Error(errorMessage);
            }
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: false
            });
            setIsLoading(false);
            throw error;
        }
    };

    const addEligibleField = () => {
        setEligibles([...eligibles, { email: '', var1: '', var2: '', var3: '', login: '' }]);
    };

    const updateEligibleField = (index, field, value) => {
        const updatedEligibles = eligibles.map((eligible, i) => {
            if (i === index) {
                return { ...eligible, [field]: value };
            }
            return eligible;
        });
        setEligibles(updatedEligibles);
    };

    const deleteEligibleField = (index) => {
        setEligibles(eligibles.filter((_, i) => i !== index));
    };

    const handleSubmitForm = () => {
        if (eligibles.length === 0) {
            triggerNotification({
                type: 'error',
                content: 'Aucun éligible à envoyer.',
                duration: 3000,
                persistent: false
            });
            setIsLoading(false);
            return;
        }

        // Validation des emails et suppression des doublons pour le formulaire
        const validData = [];
        const invalidEmails = [];
        const seenEmails = new Set();

        eligibles.forEach((eligible) => {
            const email = cleanEmail(eligible.email);

            if (!isValidEmail(email)) {
                invalidEmails.push({ email, reason: 'Email invalide' });
            } else if (seenEmails.has(email)) {
                invalidEmails.push({ email, reason: 'Email en doublon' });
            } else {
                seenEmails.add(email);
                validData.push({ ...eligible, email });
            }
        });

        if (invalidEmails.length > 0) {
            downloadInvalidEmailsExcel(invalidEmails);
            triggerNotification({
                type: 'warning',
                content: `Des emails invalides ou en doublon ont été trouvés et enregistrés dans un fichier Excel.`,
                duration: 5000,
                persistent: true
            });
        }

        if (validData.length === 0) {
            triggerNotification({
                type: 'error',
                content: 'Aucun email valide trouvé pour l\'envoi.',
                duration: 3000,
                persistent: true
            });
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        sendDataToApi(validData).then(() => {
            triggerNotification({
                type: 'success',
                content: 'Éligibles ajoutés avec succès',
                duration: 800,
                persistent: false
            });
            setIsLoading(false);
            setTimeout(() => {
                navigate(`/campagnes/${campaignId}/batch/${batchId}`);
            }, 800);
        }).catch((error) => {
            console.error("Erreur lors de l'envoi des éligibles:", error);
            setIsLoading(false);
        });
    };

    return (
        <div>
            {isLoading && <Loader />}
            <HelpPopUp instructions={helpInstructions} />

            <Breadcrumb />
            <h1>Ajouter des Éligibles</h1>
            <h2>Importez un fichier Excel</h2>
            <p>Préparez un fichier Excel avec les colonnes "Email", "Var1", "Var2", "Var3" et "Login".</p>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            <Button onClick={handleUpload} disabled={!file || isLoading}>{isLoading ? 'Chargement...' : 'Envoyer le fichier'}</Button>
            <h2>Ou remplissez les champs</h2>
            <div id="eligible-fields">
                {eligibles.map((eligible, index) => (
                    <EligibleFields
                        key={index}
                        index={index}
                        eligible={eligible}
                        onEligibleChange={updateEligibleField}
                        onDelete={deleteEligibleField}
                    />
                ))}
            </div>
            <div className="validation">
                <Button onClick={addEligibleField}>Ajouter un éligible</Button>
                <Button onClick={handleSubmitForm}>Envoyer les éligibles</Button>
            </div>
        </div>
    );
};

export default AddEligible;