import React, { useEffect, useState } from 'react';
import BatchForm from '../../../components/organisms/BatchForm/BatchForm';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { convertISO8601ToYYYYMMDDHMS } from "../../../utils/dateUtils";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch";

const EditBatch = () => {
    const { batchId, campaignId } = useParams();
    const navigate = useNavigate();
    const authFetch = useAuthFetch();
    const [sendDate, setSendDate] = useState('');
    const [name, setName] = useState('');
    const [country, setCountry] = useState(null); // Pays sélectionné
    const [countries, setCountries] = useState([]); // Liste des pays
    const { triggerNotification } = useNotification();

    // Récupération des pays
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/files-configurations/countries`);
                if (!response.ok) {
                    throw new Error('Impossible de récupérer la liste des pays');
                }
                const data = await response.json();
                const countries_codes = data.countries_codes;

                // Convertir l'objet en tableau d'options
                const countryOptions = Object.entries(countries_codes).map(([key, value]) => ({
                    value: value.name, // Utiliser le nom du pays comme valeur
                    label: value.name, // Nom du pays pour affichage
                }));
                setCountries(countryOptions);
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message || 'Erreur lors de la récupération des pays.',
                    duration: 3000,
                    persistent: false,
                });
            }
        };

        fetchCountries();
    }, [authFetch, triggerNotification]);

    // Récupération des détails du batch
    useEffect(() => {
        const fetchBatch = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/batch/fetch/${batchId}`);
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération du batch.');
                }
                const batchData = await response.json();

                // Hydratation des données du batch
                setSendDate(batchData.scheduled_date);
                setName(batchData.name);

                // Trouver l'option correspondant au pays
                setCountry({
                    value: batchData.country, // Nom du pays
                    label: batchData.country, // Nom affiché
                });
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message || 'Erreur lors de la récupération des détails du batch.',
                    duration: 3000,
                    persistent: false,
                });
            }
        };

        fetchBatch();
    }, [authFetch, batchId, triggerNotification]);

    const handleFormSubmit = async () => {
        const batchData = {
            scheduled_date: convertISO8601ToYYYYMMDDHMS(sendDate), // Format date/heure
            name: name, // Nom du batch
            country: country ? country.value : null, // Nom du pays
        };

        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/update/${batchId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(batchData), // Conversion en JSON
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Une erreur est survenue lors de la modification du batch.');
            }

            const data = await response.json();

            triggerNotification({
                type: 'success',
                content: data.message || 'Le batch a été modifié avec succès.',
                duration: 3000,
                persistent: false,
            });

            // Redirection après succès
            setTimeout(() => {
                navigate(`/campagnes/${campaignId}/batch/${batchId}`);
            }, 800);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: false,
            });
        }
    };

    return (
        <div>
            <Breadcrumb />
            <h1>Modifier un batch</h1>
            <BatchForm
                isEdit={true}
                sendDate={sendDate}
                setSendDate={setSendDate}
                name={name}
                setName={setName}
                country={country}
                setCountry={setCountry}
                countries={countries}
                onSubmit={handleFormSubmit}
            />
        </div>
    );
};

export default EditBatch;