// src/pages/Emailing/Campaign/Campaign.jsx
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import Button from '../../../components/atoms/Button/Button';
import CodeLangList from '../../../components/organisms/CodeLangList/CodeLangList';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import './Campaign.css';
import Status from "../../../components/atoms/Status/Status";
import BatchList from "../../../components/organisms/BatchList/BatchList";
import TemplatesList from "../../../components/organisms/TemplateList/TemplatesList";
import Select from "../../../components/atoms/Select/Select";
import * as XLSX from "xlsx";
import Loader from "../../../components/atoms/Loader/Loader";
import EmailPopup from "../../../components/organisms/EmailPopup/EmailPopup";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch";
import DatepickerTime from "../../../components/atoms/DatePickerTime/DatepickerTime";
import {convertISO8601ToYYYYMMDDHMS, convertToDDMMYYYY, convertToDDMMYYYYWithTime} from "../../../utils/dateUtils";
import HelpPopUp from "../../../components/organisms/HelpPopUp/HelpPopUp";

const Campaign = () => {
    const { campaignId } = useParams();
    const [campaign, setCampaign] = useState(null);
    const [codeLangs, setCodeLangs] = useState([]);
    const [batchs, setBatchs] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedCodeLangs, setSelectedCodeLangs] = useState([]);

    const [resetCodeLangSelection, setResetCodeLangSelection] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateOptions, setTemplateOptions] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [showTemplatePopup, setShowTemplatePopup] = useState(false);
    const [showUploadPopup, setShowUploadPopup] = useState(false); // Nouvel état pour la popup d'upload
    const { triggerNotification } = useNotification();
    const authFetch = useAuthFetch(); // Initialization
    const location = useLocation();
    const batchSectionRef = useRef(null);
    const [showGenerateBatchPopup, setShowGenerateBatchPopup] = useState(false);
    const [generateSendDate, setGenerateSendDate] = useState('');
    const [showEligiblePopup, setShowEligiblePopup] = useState(false);
    const [eligibleBatchId, setEligibleBatchId] = useState(null);
    const helpInstructions = [
        "Pour le template {{ placeholder }} ou {{ placeholder#1 }} {{ placeholder#2 }} pour bilangue",
        "RESPECTER LES ESPACES {{ placeholder }} et pas {{placeholder}}",
        "Pour les bannières dynamiques: **banner_1_nom.jpg** 1 représentant le code lang.",
        "Génération de Batch: se base sur les codes langues (utile quand il y a 40 langues).",
    ];
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const fetchCampaignDetails = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/campaign/fetch/${campaignId}`);
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const data = await response.json();
            setCampaign(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des détails de la campagne:", error);
        }
    }, [authFetch, campaignId]);

    const fetchCodeLangs = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/codelang/list?campaign_uuid=${campaignId}`);
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const data = await response.json();
            setCodeLangs(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des déclinaisons:", error);
        }
    }, [authFetch, campaignId]);

    const fetchBatchs = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/list?campaign_uuid=${campaignId}`);
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const data = await response.json();
            setBatchs(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des batchs:", error);
        }
    }, [authFetch, campaignId]);

    const fetchTemplates = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/template/list?campaign_uuid=${campaignId}`);
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const data = await response.json();
            setTemplates(data);

            setTemplateOptions(data.map(template => ({
                value: template.uuid,
                label: template.name
            })));
        } catch (error) {
            console.error("Erreur lors de la récupération des templates:", error);
        }
    }, [authFetch, campaignId]);

    useEffect(() => {
        if (location.hash === '#batch-section' && batchs.length > 0) {
            const batchSection = batchSectionRef.current;
            if (batchSection) {
                batchSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location, batchs]);

    useEffect(() => {
        fetchCampaignDetails();
        fetchCodeLangs();
        fetchBatchs();
        fetchTemplates();
    }, [fetchCampaignDetails, fetchCodeLangs, fetchBatchs, fetchTemplates]);

    const handleCodeLangSelectionChange = (selectedUuids) => {
        setSelectedCodeLangs(selectedUuids);
    };

    const handleNavigateToAddCodeLang = () => {
        navigate(`/campagnes/${campaignId}/declinaison`);
    };

    const handleNavigateToAddBatch = () => {
        navigate(`/campagnes/${campaignId}/ajouter-batch`);
    };

    const handleNavigateToAddTemplate = () => {
        navigate(`/campagnes/${campaignId}/ajouter-template`);
    };

    const handleNavigateToEditCampaign = () => {
        navigate(`/campagnes/${campaignId}/modifier`);
    };

    const handleNavigateToEditTemplate = (templateId) => {
        navigate(`/campagnes/${campaignId}/edit-template/${templateId}`);
    };

    const handleLinkTemplate = async () => {
        setIsLoading(true);
        if (selectedTemplate === 'delete-link') {
            await handleDeleteLink();
        } else if (selectedTemplate === 'delete-codeLang') {
            // Appeler handleDeleteLink avant handleDeleteCodeLang
            await handleDeleteLink();
            await handleDeleteCodeLang();
        } else {
            await handleLink();
        }
    };

    const handleLink = async () => {
        if (selectedTemplate && selectedCodeLangs.length > 0) {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/template/link-to-codelangs`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        template_uuid: selectedTemplate,
                        code_lang_uuids: selectedCodeLangs,
                    }),
                });

                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.error || 'Une erreur est survenue lors de la liaison');
                }

                const data = await response.json();

                triggerNotification({
                    type: 'success',
                    content: data.message || 'Liaison entre le template et les déclinaisons réussie.',
                    duration: 3000,
                    persistent: false
                });
                setResetCodeLangSelection(true);
                await fetchCodeLangs();
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message,
                    duration: 3000,
                    persistent: false
                });
                console.error("Erreur:", error);
            } finally {
                setIsLoading(false);
                setShowTemplatePopup(false);
            }
        }
    };

    const handleDownloadTranslationFile = async () => {
        if (selectedCodeLangs.length === 0) {
            triggerNotification({
                type: 'error',
                content: "Aucun CodeLang sélectionné.",
                duration: 3000,
                persistent: false
            });
            return;
        }

        setIsLoading(true);
        try {
            const url = `${config.API_URL}/emailing/placeholder/translation-file/${campaignId}`;
            const response = await authFetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ codeLangUuids: selectedCodeLangs })
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la génération du fichier de traduction');
            }

            const data = await response.json();
            downloadExcel(data.file, data.filename);
        } catch (error) {
            console.error('Erreur lors du téléchargement du fichier :', error);
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la génération du fichier de traduction',
                duration: 3000,
                persistent: false
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleUploadTranslationFile = async () => {
        if (!file) {
            triggerNotification({
                type: 'error',
                content: "Veuillez sélectionner un fichier Excel.",
                duration: 3000,
                persistent: false
            });
            return;
        }
        setIsLoading(true);
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const formattedData = { emails_objects: [], codelangs: {} };

            workbook.SheetNames.forEach(sheetName => {
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                if (sheetName === 'Emails Objects') {
                    // Traitement spécifique pour la feuille 'Emails Objects'
                    formattedData.emails_objects = jsonData.map(row => ({
                        declinations: row['Declinations'],
                        object: row['Object']
                    }));
                } else {
                    // Traitement pour les autres feuilles
                    formattedData.codelangs[sheetName] = jsonData.map(row => ({
                        name: row['Placeholders'],
                        value: row['Value']
                    }));
                }
            });

            try {
                const response = await authFetch(`${config.API_URL}/emailing/placeholder/update-multiple/${campaignId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formattedData)
                });

                if (!response.ok) {
                    const responseData = await response.json();
                    throw new Error(responseData.error || 'Erreur lors de l’envoi des données.');
                }

                const responseData = await response.json();
                triggerNotification({
                    type: 'success',
                    content: responseData.message || 'Traductions ajoutées avec succès',
                    duration: 3000,
                    persistent: false
                });
                setShowUploadPopup(false); // Fermer la popup après l'upload
                setFile(null); // Réinitialiser le fichier sélectionné
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message || 'Erreur lors de la communication avec l’API.',
                    duration: 3000,
                    persistent: false
                });
            } finally {
                setIsLoading(false);
            }
        };

        reader.readAsBinaryString(file);
    }

    const downloadExcel = (encodedContent, filename) => {
        const link = document.createElement('a');
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${encodedContent}`;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDeleteLink = async () => {
        const confirmDelete = window.confirm("Attention ! Les traductions des déclinaisons sélectionnées seront supprimées définitivement. Assurez-vous de télécharger le modèle de traduction avant la suppression.\n\nContinuez malgré tout ?");
        if (confirmDelete) {
            try {
                for (const codeLangUuid of selectedCodeLangs) {
                    const response = await authFetch(`${config.API_URL}/emailing/placeholder/delete/${codeLangUuid}`, {
                        method: 'DELETE'
                    });

                    if (!response.ok) {
                        const data = await response.json();
                        throw new Error(data.error || 'Une erreur est survenue lors de la suppression de la liaison');
                    }
                }

                triggerNotification({
                    type: 'success',
                    content: 'Suppression de la liaison réussie',
                    duration: 3000,
                    persistent: false
                });
                await fetchCodeLangs();
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message,
                    duration: 3000,
                    persistent: false
                });
                console.error("Erreur:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    const handleOpenEmailPopup = () => {
        setShowEmailPopup(true);
    };

    const handleCloseEmailPopup = () => {
        setShowEmailPopup(false);
    };

    const handleSendEmails = async (emails) => {
        setIsLoading(true);

        const emailData = {
            emails: emails,
            codelangs_uuid: selectedCodeLangs,
        };
        try {
            const response = await authFetch(`${config.API_URL}/emailing/email/bat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailData),
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Une erreur est survenue lors de l’envoi ');
            }
            if (data.errors && data.errors.length > 0) {
                triggerNotification({
                    type: 'error',
                    content: data.errors.join(', '),
                    duration: 3000,
                    persistent: false
                });
            }
            if (data.success) {
                triggerNotification({
                    type: 'success',
                    content: `Emails envoyés avec succès : ${data.success}`,
                    duration: 3000,
                    persistent: false
                });
            }

        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: false
            });
        } finally {
            setIsLoading(false);
            setShowEmailPopup(false);
        }
    };

    const handleToggleBatchStatus = async (batchId, currentStatus) => {
        const newStatus = currentStatus === 'ready' ? 'created' : 'ready';

        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/status/update/${batchId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la mise à jour du statut du batch');
            }

            setBatchs(batchs.map(batch => batch.uuid === batchId ? { ...batch, status: newStatus } : batch));
            triggerNotification({
                type: 'success',
                content: 'Statut du batch mis à jour avec succès.',
                duration: 3000,
                persistent: false
            });
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut du batch:", error);
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        }
    };

    const handleDeleteCodeLang = async () => {
        setIsLoading(true);
        try {
            for (const codeLangUuid of selectedCodeLangs) {
                const response = await authFetch(`${config.API_URL}/emailing/codelang/delete/${codeLangUuid}`, {
                    method: 'DELETE'
                });

                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.error || 'Une erreur est survenue lors de la suppression du codeLang');
                }
            }

            triggerNotification({
                type: 'success',
                content: 'Suppression des codeLangs réussie',
                duration: 3000,
                persistent: false
            });
            setResetCodeLangSelection(true);
            await fetchCodeLangs();
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
            console.error("Erreur lors de la suppression:", error);
        } finally {
            setIsLoading(false);
            setShowTemplatePopup(false);
        }
    };

    const handleDownloadProofreadZip = async () => {
        if (selectedCodeLangs.length === 0) {
            triggerNotification({
                type: 'error',
                content: "Aucun CodeLang sélectionné.",
                duration: 3000,
                persistent: false
            });
            return;
        }

        setIsLoading(true);
        try {
            const response = await authFetch(`${config.API_URL}/emailing/placeholder/proofread/${campaignId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ codeLangUuids: selectedCodeLangs }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la génération du fichier ZIP');
            }

            // Récupérer le fichier ZIP en tant que Blob
            const blob = await response.blob();

            // Extraire le nom du fichier depuis l'en-tête Content-Disposition
            const contentDisposition = response.headers.get('content-disposition');
            let filename = 'proofread.zip';
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)|filename="(.+)"|filename=(.+)/);
                if (filenameMatch) {
                    if (filenameMatch[1]) {
                        // filename*=UTF-8''...
                        filename = decodeURIComponent(filenameMatch[1]);
                    } else if (filenameMatch[2]) {
                        // filename="..."
                        filename = filenameMatch[2];
                    } else if (filenameMatch[3]) {
                        // filename=...
                        filename = filenameMatch[3];
                    }
                }
            }

            // Créer un lien pour le téléchargement
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            triggerNotification({
                type: 'success',
                content: 'Le fichier ZIP a été téléchargé avec succès.',
                duration: 3000,
                persistent: false
            });
        } catch (error) {
            console.error('Erreur lors du téléchargement du fichier ZIP :', error);
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la génération du fichier ZIP',
                duration: 3000,
                persistent: false
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteBatch = async (batchId) => {
        const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce batch ?");
        if (confirmDelete) {
            setIsLoading(true);
            try {
                const response = await authFetch(`${config.API_URL}/emailing/batch/delete/${batchId}`, {
                    method: 'DELETE'
                });
                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.error || 'Erreur lors de la suppression du batch');
                }

                // Filtrer le batch supprimé
                const updatedBatchs = batchs.filter(batch => batch.uuid !== batchId);

                if (updatedBatchs.length > 0) {
                    // Réassigner les positions seulement s'il reste des batches
                    const reindexedBatchs = updatedBatchs.map((batch, index) => ({
                        ...batch,
                        position: index + 1
                    }));

                    // Mettre à jour les positions côté serveur
                    await updateBatchPositionsOnServer(reindexedBatchs);

                    // Mettre à jour l'état local des batches
                    setBatchs(reindexedBatchs);
                } else {
                    // Si aucun batch restant, mettre à jour l'état directement
                    setBatchs([]);
                }

                triggerNotification({
                    type: 'success',
                    content: 'Batch supprimé avec succès',
                    duration: 3000,
                    persistent: false
                });
            } catch (error) {
                console.error('Erreur lors de la suppression du batch:', error);
                triggerNotification({
                    type: 'error',
                    content: error.message,
                    duration: 3000,
                    persistent: false
                });
            } finally {
                setIsLoading(false);
            }
        }
    };

    const updateBatchPositionsOnServer = async (updatedBatchs) => {
        try {
            // Préparer les données à envoyer à l'API
            const batchPositions = updatedBatchs.map((batch) => ({
                uuid: batch.uuid,
                position: batch.position,
            }));

            const response = await authFetch(`${config.API_URL}/emailing/batch/position`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ batches: batchPositions }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la mise à jour des positions');
            }

            // Optionnel : Vous pouvez traiter la réponse si nécessaire
        } catch (error) {
            throw new Error(error.message || 'Erreur lors de la mise à jour des positions sur le serveur');
        }
    };

    const handleBatchPositionChange = async (updatedBatchs) => {
        setIsLoading(true);
        try {
            // Préparer les données à envoyer à l'API
            const batchPositions = updatedBatchs.map((batch) => ({
                uuid: batch.uuid,
                position: batch.position, // Utiliser la position mise à jour
            }));

            const response = await authFetch(`${config.API_URL}/emailing/batch/position`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ batches: batchPositions }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la mise à jour des positions');
            }

            // Mettre à jour l'état local des batches
            setBatchs(updatedBatchs);

            triggerNotification({
                type: 'success',
                content: 'Positions des batches mises à jour avec succès.',
                duration: 3000,
                persistent: false,
            });
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false,
            });
            console.error("Erreur lors de la mise à jour des positions:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGenerateBatches = async () => {
        if (!generateSendDate) {
            triggerNotification({
                type: 'error',
                content: "Veuillez sélectionner une date et heure.",
                duration: 3000,
                persistent: false
            });
            return;
        }

        setIsLoading(true);
        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/generate`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    scheduled_date: convertISO8601ToYYYYMMDDHMS(generateSendDate),
                    campaign_uuid: campaignId // Ajout de campaign_uuid
                }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Erreur lors de la génération des batchs');
            }

            triggerNotification({
                type: 'success',
                content: 'Batchs générés avec succès.',
                duration: 3000,
                persistent: false
            });
            setShowGenerateBatchPopup(false);
            setGenerateSendDate('');
            await fetchBatchs();
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la génération des batchs',
                duration: 3000,
                persistent: false
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenEligiblePopup = (batchId) => {
        setEligibleBatchId(batchId);
        setShowEligiblePopup(true);
    };

    const handleDeleteEligibles = async (batchId) => {
        const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer les éligibles de ce batch ?");
        if (confirmDelete) {
            setIsLoading(true);
            try {
                const response = await authFetch(`${config.API_URL}/emailing/eligible/delete`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ batch_uuid: batchId }),
                });

                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.error || 'Erreur lors de la suppression des éligibles');
                }

                triggerNotification({
                    type: 'success',
                    content: 'Éligibles supprimés avec succès',
                    duration: 3000,
                    persistent: false
                });
                await fetchBatchs();
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message,
                    duration: 3000,
                    persistent: false
                });
                console.error("Erreur lors de la suppression des éligibles:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };


    const handleUploadEligibleFile = async () => {
        if (!file) {
            triggerNotification({
                type: 'error',
                content: "Veuillez sélectionner un fichier Excel.",
                duration: 3000,
                persistent: false
            });
            return;
        }
        setIsLoading(true);
        const reader = new FileReader();

        reader.onload = async (e) => {
            try {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                const requiredHeaders = ["Email", "Var1", "Var2", "Var3", "Login"];
                const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
                const missingHeaders = requiredHeaders.filter(h => !headers.includes(h));
                if (missingHeaders.length > 0) {
                    triggerNotification({
                        type: 'error',
                        content: `Les entêtes suivantes sont manquantes dans le fichier Excel : ${missingHeaders.join(", ")}`,
                        duration: 3000,
                        persistent: true
                    });
                    setIsLoading(false);
                    return;
                }

                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                const eligibles = jsonData.map(row => ({
                    email: cleanEmail(row['Email']?.trim() || ''),
                    var1: row['Var1'] || '',
                    var2: row['Var2'] || '',
                    var3: row['Var3'] || '',
                    login: row['Login'] || '',
                }));

                // Validation des emails et suppression des doublons
                const validData = [];
                const invalidEmails = [];
                const seenEmails = new Set();

                eligibles.forEach((eligible) => {
                    const email = eligible.email;

                    if (!isValidEmail(email)) {
                        invalidEmails.push({ email, reason: 'Email invalide' });
                    } else if (seenEmails.has(email)) {
                        invalidEmails.push({ email, reason: 'Email en doublon' });
                    } else {
                        seenEmails.add(email);
                        validData.push(eligible);
                    }
                });

                if (invalidEmails.length > 0) {
                    // Télécharger le fichier Excel des emails invalides
                    downloadInvalidEmailsExcel(invalidEmails);
                    triggerNotification({
                        type: 'warning',
                        content: `Des emails invalides ou en doublon ont été trouvés et enregistrés dans un fichier Excel.`,
                        duration: 5000,
                        persistent: true
                    });
                }

                if (validData.length === 0) {
                    triggerNotification({
                        type: 'error',
                        content: 'Aucun email valide trouvé pour l\'ajout.',
                        duration: 3000,
                        persistent: true
                    });
                    setIsLoading(false);
                    return;
                }

                const chunkSize = 20000;
                const chunks = [];
                for (let i = 0; i < validData.length; i += chunkSize) {
                    chunks.push(validData.slice(i, i + chunkSize));
                }

                let errorOccurred = false;

                for (const chunk of chunks) {
                    if (errorOccurred) break;

                    try {
                        await sendDataToApi(chunk);
                    } catch (error) {
                        console.error("Erreur lors de l'envoi d'un chunk d'éligibles:", error);
                        errorOccurred = true;
                        triggerNotification({
                            type: 'error',
                            content: error.message || 'Erreur lors de l’ajout des éligibles.',
                            duration: 3000,
                            persistent: false
                        });
                        setIsLoading(false);
                    }
                }

                if (!errorOccurred) {
                    triggerNotification({
                        type: 'success',
                        content: 'Éligibles ajoutés avec succès',
                        duration: 3000,
                        persistent: false
                    });
                    setShowEligiblePopup(false); // Fermer la popup après l'upload
                    setFile(null); // Réinitialiser le fichier sélectionné
                    await fetchBatchs();
                }
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message || 'Erreur lors de la communication avec l’API.',
                    duration: 3000,
                    persistent: false
                });
                console.error("Erreur lors de l'ajout des éligibles:", error);
            } finally {
                setIsLoading(false);
            }
        };

        reader.readAsBinaryString(file);

        // Fonction pour télécharger les emails invalides
        const downloadInvalidEmailsExcel = (invalidEmails) => {
            const worksheet = XLSX.utils.json_to_sheet(invalidEmails);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Emails Invalides');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'emails_invalides.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        };

        // Fonction pour envoyer les données à l'API
        const sendDataToApi = async (chunk) => {
            const payload = chunk.map(eligible => ({
                ...eligible,
                batch_uuid: eligibleBatchId // Assurez-vous que eligibleBatchId est correctement défini
            }));

            try {
                const response = await authFetch(`${config.API_URL}/emailing/eligible/add`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    const responseData = await response.json();
                    const errorMessage = responseData.error || 'Erreur lors de l’ajout des éligibles.';
                    throw new Error(errorMessage);
                }
            } catch (error) {
                throw error;
            }
        };
    };

    const cleanEmail = (email) => {
        // eslint-disable-next-line no-control-regex
        return email.replace(/[\s\x00-\x1F\x7F-\x9F]+/g, "").trim();
    };

    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };



    return (
        <div className="campaign-detail">
            {isLoading && <Loader />}

            <Breadcrumb />
            <HelpPopUp instructions={helpInstructions} />

            {campaign && (
                <div className="campaign-info">
                    <h1>Campagne {campaign.name} du {convertToDDMMYYYY(campaign.theoric_scheduled_date)}</h1>
                    <Button onClick={handleNavigateToEditCampaign}>Modifier la campagne</Button>
                    <div className="campaign-details">
                        <p><strong>Nom:</strong> {campaign.name}</p>
                        <p><strong>Date théorique d'envoi:</strong> {convertToDDMMYYYY(campaign.theoric_scheduled_date)}</p>
                        <p><strong>Date de création:</strong> {convertToDDMMYYYYWithTime(campaign.create_date)}</p>
                        <Status status={campaign.status} />
                    </div>
                </div>
            )}

            {/* Section Templates */}
            <div className="templates-section">
                <h2>Templates</h2>
                <Button onClick={handleNavigateToAddTemplate}>Ajouter un template</Button>
                <TemplatesList templates={templates} handleNavigateToEditTemplate={handleNavigateToEditTemplate} />
            </div>

            {/* Nouveau bloc d'actions avec un arrière-plan reconnaissable */}
            <div className="action-buttons-block">
                <Button onClick={handleNavigateToAddCodeLang}>Ajouter des déclinaisons</Button>
                <Button onClick={() => setShowUploadPopup(true)} disabled={isLoading}>
                    {isLoading ? 'Chargement...' : 'Uploader les traductions'}
                </Button>
            </div>

            {/* Popup d'Upload des Traductions */}
            {showUploadPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h3>Uploader les Traductions</h3>
                        <input
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                        />
                        <div className="popup-buttons">
                            <Button
                                onClick={handleUploadTranslationFile}
                                disabled={!file || isLoading}
                            >
                                {isLoading ? 'Chargement...' : 'Uploader'}
                            </Button>
                            <Button onClick={() => { setShowUploadPopup(false); setFile(null); }}>Annuler</Button>
                        </div>
                    </div>
                </div>
            )}

            {/* Menu Sticky pour Déclinaisons Sélectionnées */}
            {selectedCodeLangs.length > 0 && (
                <div className="sticky-menu">
                    <div className="sticky-menu-content">
                        <Button onClick={handleDownloadProofreadZip} size="small">
                            ZIP (RELECTURE)
                        </Button>
                        <Button onClick={handleOpenEmailPopup} size="small">
                            BAT
                        </Button>
                        <Button onClick={handleDownloadTranslationFile} size="small">
                            Modèle (traduction)
                        </Button>
                        <Button onClick={() => setShowTemplatePopup(true)} size="small">
                            Lier/Supprimer
                        </Button>
                        <Button onClick={() => setResetCodeLangSelection(true)} size="small" className="btn-danger">
                            Déselectionner
                        </Button>
                    </div>
                </div>
            )}

            {/* Popup de Liaison de Template */}
            {showTemplatePopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h3>Lier ou Supprimer un Template</h3>
                        <Select
                            value={selectedTemplate}
                            onChange={(e) => setSelectedTemplate(e.target.value)}
                            options={[
                                { value: '', label: 'Sélectionnez un template' },
                                ...templateOptions,
                                { value: 'delete-link', label: 'Supprimer la liaison de template' },
                                { value: 'delete-codeLang', label: 'Supprimer la déclinaison' }
                            ]}
                        />
                        <div className="popup-buttons">
                            <Button onClick={handleLinkTemplate} disabled={isLoading || selectedTemplate === ''}>
                                {isLoading ? 'Chargement...' :
                                    selectedTemplate === 'delete-link' ? 'Supprimer la Liaison' :
                                        selectedTemplate === 'delete-codeLang' ? 'Supprimer Déclinaison' :
                                            'Lier le Template Sélectionné'
                                }
                            </Button>
                            <Button onClick={() => setShowTemplatePopup(false)}>Annuler</Button>
                        </div>
                    </div>
                </div>
            )}

            {/* Popup Email */}
            {showEmailPopup && (
                <EmailPopup
                    onClose={handleCloseEmailPopup}
                    onSend={handleSendEmails}
                />
            )}

            {/* Liste des Déclinaisons */}
            <div className="declinaison-section">
                <CodeLangList
                    codeLangs={codeLangs}
                    campaignId={campaignId}
                    onSelectionChange={handleCodeLangSelectionChange}
                    resetSelection={resetCodeLangSelection}
                    setResetSelection={setResetCodeLangSelection}
                />
            </div>

            {/* Section Batchs */}
            <div className="batchs-section" id="batch-section" ref={batchSectionRef}>
                    <h2>Batchs</h2>
                    <Button onClick={handleNavigateToAddBatch}>Ajouter un batch</Button>
                    <Button onClick={() => setShowGenerateBatchPopup(true)} disabled={isLoading}>
                        {isLoading ? 'Chargement...' : 'Générer les batchs'}
                    </Button>
                {showGenerateBatchPopup && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <h3>Générer les Batchs</h3>
                            <DatepickerTime
                                value={generateSendDate}
                                onChange={(e) => setGenerateSendDate(e.target.value)}
                            />
                            <div className="popup-buttons">
                                <Button onClick={handleGenerateBatches} disabled={isLoading}>
                                    {isLoading ? 'Chargement...' : 'Générer'}
                                </Button>
                                <Button onClick={() => setShowGenerateBatchPopup(false)}>Annuler</Button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="batchs-warning">
                        <strong>Attention :</strong> Merci de ne pas utiliser les <strong>Batch</strong> pour
                        les <strong>BAT</strong>. La fonction BAT est faite pour cela.
                        <br/><br/>
                        Une déclinaison d'email n'est pas modifiable après envoi. Un passage d'unilingue à bilingue
                        pourrait
                        être compromis en cas d'envoi inutile pour un pays spécifique.
                    </div>
                    <BatchList
                        campaignId={campaignId}
                        batchs={batchs}
                        onToggleStatus={handleToggleBatchStatus}
                        onDeleteBatch={handleDeleteBatch}
                        onBatchPositionChange={handleBatchPositionChange}
                        onAddEligibles={handleOpenEligiblePopup}
                        onDeleteEligibles={handleDeleteEligibles}
                    />

                {/* Popup d'Ajout des Éligibles */}
                {showEligiblePopup && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <h3>Ajouter des Éligibles</h3>
                            <input
                                type="file"
                                accept=".xlsx, .xls"
                                onChange={handleFileChange}
                            />
                            <div className="popup-buttons">
                                <Button
                                    onClick={handleUploadEligibleFile}
                                    disabled={!file || isLoading}
                                >
                                    {isLoading ? 'Chargement...' : 'Uploader'}
                                </Button>
                                <Button onClick={() => { setShowEligiblePopup(false); setFile(null); }}>Annuler</Button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
            </div>
            );

            };

            export default Campaign;
