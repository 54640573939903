// src/components/organisms/CodeLangList/CodeLangList.jsx
import React, { useState, useEffect } from 'react';
import './CodeLangList.css';
import Checkbox from "../../atoms/Checkbox/Checkbox";
import Button from "../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";

const CodeLangList = ({
                          codeLangs,
                          onSelectionChange,
                          campaignId,
                          resetSelection,
                          setResetSelection,
                      }) => {
    const navigate = useNavigate();
    const [selectedCodeLangs, setSelectedCodeLangs] = useState([]);

    const selectAllCodeLangs = () => {
        setSelectedCodeLangs(codeLangs.map(lang => lang.uuid));
    };

    const toggleCodeLangSelection = (uuid) => {
        setSelectedCodeLangs(prevSelected => {
            if (prevSelected.includes(uuid)) {
                return prevSelected.filter(id => id !== uuid);
            } else {
                return [...prevSelected, uuid];
            }
        });
    };

    useEffect(() => {
        onSelectionChange(selectedCodeLangs);
        if (resetSelection) {
            setSelectedCodeLangs([]);
            setResetSelection();
        }
    }, [selectedCodeLangs, onSelectionChange, resetSelection, setResetSelection]);

    return (
        <div>
            <Button onClick={selectAllCodeLangs}>Tout sélectionner</Button>

            <ul className="codeLang-list">
                {codeLangs
                    .slice()
                    .sort((a, b) => a.country.localeCompare(b.country))
                    .map((codeLang) => (
                        <li
                            key={codeLang.uuid}
                            className={`codeLang-item ${selectedCodeLangs.includes(codeLang.uuid) ? 'bounce-effect' : ''}`}
                            onClick={() => toggleCodeLangSelection(codeLang.uuid)}
                        >
                            <div className="codeLang-country">
                                {codeLang.country}
                            </div>
                            <div className="codeLang-content">
                                <p>Objet: <strong>{codeLang.email_object}</strong></p>
                                <p>Code Lang: <strong>{codeLang.code_lang}</strong></p>
                                <p className={!codeLang.template_uuid ? 'alert-message template-uuid-info' : 'template-uuid-info'}>
                                    (Template UUID: {codeLang.template_uuid || 'Aucun'})
                                </p>
                            </div>
                            <Checkbox
                                checked={selectedCodeLangs.includes(codeLang.uuid)}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    toggleCodeLangSelection(codeLang.uuid);
                                }}
                            />
                            {codeLang.template_uuid &&
                                <Button onClick={() => navigate(`/campagnes/${campaignId}/code-lang/${codeLang.uuid}`)}>Gérer</Button>
                            }
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default CodeLangList;
