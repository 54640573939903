import React from 'react';
import Button from '../../atoms/Button/Button';
import './BatchForm.css';
import DatepickerTime from "../../atoms/DatePickerTime/DatepickerTime";
import { convertISO8601ToYYYYMMDDHMS } from "../../../utils/dateUtils";
import TextInput from "../../atoms/TextInput/TextInput";
import Select from 'react-select';

const BatchForm = ({
                       sendDate,
                       setSendDate,
                       name,
                       setName,
                       country,
                       setCountry,
                       countries,
                       onSubmit,
                   }) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        const batchData = {
            name: name,
            scheduled_date: convertISO8601ToYYYYMMDDHMS(sendDate),
            country: country ? country.value : null,
        };
        onSubmit(batchData);
    };

    return (
        <div className="batch-form-container">
            <form className="batch-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Nom du batch</label>
                    <TextInput
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Nom du batch"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="sendDate">Date et heure d'envoi</label>
                    <DatepickerTime
                        id="sendDate"
                        value={sendDate}
                        onChange={(e) => setSendDate(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="country">Pays</label>
                    <Select
                        id="country"
                        value={country}
                        onChange={setCountry}
                        options={countries}
                        placeholder="Sélectionner un pays"
                        isClearable
                    />
                </div>
                <Button type="submit">Enregistrer</Button>
            </form>
        </div>
    );
};

export default BatchForm;