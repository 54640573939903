import React from 'react';
import './EligibleList.css';

const EligibleList = ({ eligibles }) => {

    if (!eligibles || eligibles.length === 0) {
        return <p>Aucun eligible disponible pour le moment.</p>;
    }


    return (
        <ul className="eligible-list">
            {eligibles.map((eligible) => (
                <li key={eligible.uuid} className="eligible-item">
                    <div>
                        <div>
                            <p>

                                {eligible.email}
                                {eligible.var1 && ` - var1: ${eligible.var1}`}
                                {eligible.var2 && ` - var2: ${eligible.var2}`}
                                {eligible.var3 && ` - var3: ${eligible.var3}`}
                                {eligible.login && ` - login: ${eligible.login}`}
                            </p>
                            <span className="eligible-icon">
                                    {eligible.isSent && <img src="/green-comma.png" alt="," className="green-comma"/>}
                                </span>
                            <span className="eligible-icon">
                                    {eligible.isError && <img src="/red-cross.png" alt="," className="red-cross"/>}
                            </span>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default EligibleList;
