import React, { useState, useEffect } from 'react';
import BatchForm from '../../../components/organisms/BatchForm/BatchForm';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useParams, useNavigate } from "react-router-dom";
import { convertISO8601ToYYYYMMDDHMS } from "../../../utils/dateUtils";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch";

const AddBatch = () => {
    const { campaignId } = useParams();
    const navigate = useNavigate();
    const authFetch = useAuthFetch();
    const [sendDate, setSendDate] = useState('');
    const [name, setName] = useState('');
    const [country, setCountry] = useState(null); // Changement ici
    const [countries, setCountries] = useState([]); // Liste des pays
    const { triggerNotification } = useNotification();

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/files-configurations/countries`);
                if (!response.ok) {
                    throw new Error('Impossible de récupérer la liste des pays');
                }
                const data = await response.json();
                let countries_codes = data.countries_codes;
                // Convertir l'objet en tableau d'options pour React Select
                const countryOptions = Object.keys(countries_codes).map(key => ({
                    value: countries_codes[key].name,
                    label: countries_codes[key].name
                }));
                setCountries(countryOptions);
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: error.message || 'Erreur lors de la récupération des pays.',
                    duration: 3000,
                    persistent: false
                });
            }
        };

        fetchCountries();
    }, [authFetch, triggerNotification]);

    const handleFormSubmit = async () => {
        const batchData = {
            campaign_uuid: campaignId,
            scheduled_date: convertISO8601ToYYYYMMDDHMS(sendDate),
            name: name,
            country: country ? country.value : null,
        };

        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(batchData),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de l’ajout du batch');
            }

            const data = await response.json();

            triggerNotification({
                type: 'success',
                content: data.message || 'Le batch a été ajouté avec succès.',
                duration: 3000,
                persistent: false
            });
            setTimeout(() => {
                navigate(`/campagnes/${campaignId}#batch-section`);
            }, 800);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: false
            });
        }
    };

    return (
        <div>
            <Breadcrumb />
            <h1>Ajouter un batch</h1>
            <BatchForm
                isEdit={false}
                sendDate={sendDate}
                setSendDate={setSendDate}
                name={name}
                setName={setName}
                country={country}
                setCountry={setCountry}
                countries={countries}
                onSubmit={handleFormSubmit}
            />
        </div>
    );
};

export default AddBatch;