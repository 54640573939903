import React from 'react';
import './BatchList.css';
import StatusAtom from "../../atoms/Status/Status";
import Button from "../../atoms/Button/Button";
import { useNavigate } from 'react-router-dom';
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import { convertToDDMMYYYYWithTime } from '../../../utils/dateUtils';

// Importations de dnd-kit
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const BatchList = ({ campaignId, batchs, onToggleStatus, onDeleteBatch, onBatchPositionChange, onAddEligibles, onDeleteEligibles }) => {

        const navigate = useNavigate();

    // Assurez-vous que les Hooks sont appelés avant tout retour
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        })
    );

    if (!batchs || batchs.length === 0) {
        return <p className="alert-message">Aucun batch disponible pour le moment.</p>;
    }

    const handleShowBatchClick = (batchId) => {
        navigate(`/campagnes/${campaignId}/batch/${batchId}`);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = batchs.findIndex((batch) => batch.uuid === active.id);
            const newIndex = batchs.findIndex((batch) => batch.uuid === over.id);

            const newBatchs = arrayMove(batchs, oldIndex, newIndex).map((batch, index) => ({
                ...batch,
                position: index + 1,
            }));

            onBatchPositionChange(newBatchs);
        }
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={batchs.map((batch) => batch.uuid)}
                strategy={rectSortingStrategy}
            >
                <ul className="batch-list">
                    {batchs.map((batch) => (
                        <SortableBatchItem
                            key={batch.uuid}
                            batch={batch}
                            handleShowBatchClick={handleShowBatchClick}
                            onToggleStatus={onToggleStatus}
                            onDeleteBatch={onDeleteBatch}
                            onAddEligibles={onAddEligibles}
                            onDeleteEligibles={onDeleteEligibles}
                        />
                    ))}
                </ul>
            </SortableContext>
        </DndContext>
    );
};

export default BatchList;

// Composant pour les éléments triables
const SortableBatchItem = ({ batch, handleShowBatchClick, onToggleStatus, onDeleteBatch, onAddEligibles, onDeleteEligibles }) => {

        const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: batch.uuid });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    return (
        <li
            ref={setNodeRef}
            style={style}
            className="batch-item"
        >
            {/* Ajout des attributs pour le drag and drop */}
            <div className="batch-handle" {...attributes} {...listeners}>
                {/* Icône de poignée de déplacement */}
                <div className="drag-icon">
                    {/* Icône SVG pour la poignée de déplacement */}
                    <svg width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#888888" d="M3 10h18v2H3v-2zm0 4h18v2H3v-2z" />
                    </svg>
                </div>
                {/* Position */}
                <div className="batch-position">
                    <span>{batch.position}</span>
                </div>
            </div>

            {/* Contenu du batch */}
            <div className="batch-content">
                {/* Première ligne : statut, pays, date d'envoi */}
                <div className="batch-row">
                    <div className="batch-status">
                        <StatusAtom status={batch.status === 'error' ? 'stopped' : batch.status}/>
                    </div>

                    <div className="batch-country">
                        <p>{batch.country}</p>
                        <p>{batch.name}</p>
                    </div>
                    <div className="batch-date">
                        <p>
                            Date d'envoi prévue : <strong>{convertToDDMMYYYYWithTime(batch.scheduled_date)}</strong>
                        </p>
                    </div>
                </div>

                {/* Deuxième ligne : boutons et stats */}
                <div className="batch-row">
                <div className="batch-actions">
                        {(batch.status === 'ready' || batch.status === 'created') && (
                            <ToggleButton
                                value={batch.status === 'ready'}
                                onChange={() => onToggleStatus(batch.uuid, batch.status)}
                            />
                        )}
                        <Button onClick={() => handleShowBatchClick(batch.uuid)}>
                            Voir le batch
                        </Button>
                        <Button
                            onClick={() => {
                                if (batch.count_eligibles > 0) {
                                    onDeleteEligibles(batch.uuid);
                                } else {
                                    onAddEligibles(batch.uuid);
                                }
                            }}
                        >
                        {batch.count_eligibles > 0 ? 'Supprimer les éligibles' : 'Ajouter des éligibles'}
                    </Button>

                    <Button
                            className="delete-button"
                            onClick={() => onDeleteBatch(batch.uuid)}
                        >
                            Supprimer
                        </Button>
                    </div>
                    <div className="batch-stats">
                        <p>
                            {batch.count_is_sent || 0} / {batch.count_eligibles || 0}
                        </p>
                    </div>
                </div>
            </div>
        </li>
    );
};
